body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  padding-top: 0;
}

.simplebar-scrollbar::before {
  background: rgb(122, 122, 122) !important;
}

.row-disabled {
  background-color: rgb(255, 185, 185) !important;
}

.row-disabled:hover td {
  background-color: rgb(255, 185, 185) !important;
}

.row-disabled>.ant-table-column-sort {
  background-color: rgb(255, 185, 185) !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.m-0 {
  margin: 0;
}

button.gm-ui-hover-effect {
  visibility: hidden;
}

.small-font {
  font-size: 12px;
}

.compact {
  font-size: 10px;
}

.table-with-spacing>table {
  border-spacing: 50px 0;
}

.input-search {
  display: flex;
  justify-content: flex-end;
  width: 300px;
}

.column-dark {
  background-color: rgb(45, 62, 179) !important;
  color: white !important;
}

.advanced-search {
  max-width: none;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 24px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.danger-float-btn .ant-float-btn-body {
  background-color: #ff4d4f;
}

.danger-float-btn:hover .ant-float-btn-body {
  background-color: #ff7875;
}

.card-buttons {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 8px;
  margin: -8px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  text-align: right;
}

.degrade-button {
  font-size: 14px;
  padding: 0 15px;
  display: inline-block;
  transition: transform .3s, box-shadow .3s;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 12px 18px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: translateY(4px);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
}

.gm-style-iw-chr {
  display: none !important; /* Oculta el botón de cerrar de InfoWindow de GoogleMap */
}

.gm-style-iw-d {
  margin-top: 12px;
}

.color2 {
  background-color: #e4ebf770; /* Color de fondo alternativo 2 */
}

.ant-comment-content-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}

.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}

.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}

.ant-comment-content-author-time {
    color: #ccc;
    white-space: nowrap;
    cursor: auto;
}

.ant-comment-content {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}

.ant-input-dark {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #141414;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #424242;
  border-radius: 6px;
  transition: all 0.2s;
}

.ant-input-dark {
  transition: border-color 0.2s, all 0.2s;
}

.ant-input-dark:hover{
  border-color: #4859AE; 
  outline: none; 
}

.ant-input-dark:focus {
  border-color: #283695; 
  outline: none; 
}

.ant-input-light {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.ant-input-light:hover{
  border-color: #4859AE; 
  outline: none; 
}

.ant-input-light:focus {
  border-color: #283695; 
  outline: none; 
}

.custom-timeline-bg-item-head > .ant-timeline-item-head {
  background-color: transparent;
}

.custom-timeline-scale-item-head > .ant-timeline-item-head {
  transform: scale(1.5);
  background-color: transparent;
}